.use-cases {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 50px 0;
  padding: 80px 100px;
  background: #fafafa;
  border-radius: 0px 133px 0px 0px;
  background: transparent linear-gradient(90deg, #096ef2 0%, #0092ff 100%) 0% 0%
    no-repeat padding-box;
  h2 {
    // font-size: $product-sections-header-font-size-desktop;
    // line-height: $product-sections-header-line-height-desktop;
    font-weight: 400;
    color: #ffffff;

    span {
      font-weight: 600;
    }
  }

  p.tagline {
    // font-size: $product-pages-body-text-font-size-desktop;
    // line-height: $product-pages-body-text-line-height-desktop;
    font-weight: 400;
    color: #ffffff;
    margin-top: 20px;
    text-align: center;
  }

  .use-case-list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 40px;

    .use-case-block {
      width: calc(100% / 4);
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 30px;
      position: relative;

      .head {
        display: flex;
        flex-flow: row;
        align-items: center;
        width: 100%;
      }

      label.coming-soon {
        font-size: 9px;
        font-weight: 700;
        color: #2e3552;
        padding: 5px 12px;
        background: #ffcccc;
        border-radius: 14px;
        text-transform: uppercase;
        margin-top: 20px;
        align-self: flex-start;
      }

      .image {
        img {
          height: 50px;
        }
      }

      h4 {
        // font-size: $product-sections-subheader-font-size-desktop;
        // line-height: $product-sections-subheader-line-height-desktop;
        font-weight: 600;
        color: #ffffff;
        margin-left: 25px;
        text-align: left;
      }

      p {
        // font-size: $product-pages-body-text-font-size-desktop;
        // line-height: $product-pages-body-text-line-height-desktop;
        font-weight: 400;
        color: #3c3c3c;
        margin-top: 20px;
        text-align: left;
        color: #ffffff;

        a {
          color: #ffffff;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .examples-block {
    width: 90%;
    margin-top: 70px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    h2 {
      text-align: center;
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      color: #ffffff;
      width: 100%;
    }

    .examples-block-list {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      gap: 50px;
      width: 100%;
      margin-top: 40px;

      .examples-unit-block {
        width: calc(100% / 7);
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        height: 100%;
        box-sizing: border-box;

        .image {
          width: 75px;
          height: 75px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        h4 {
          text-align: center;
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
  }

  .more-use-cases {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 80px 0 0 0;

    p {
      font-size: 26px;
      line-height: 34px;
      font-weight: 400;
      color: rgba(60, 60, 60, 1);
      text-align: center;
    }

    .more-use-cases-list {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 80px;

      .more-use-cases-block {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin: 0 30px;

        img {
          height: 50px;
        }

        label {
          margin-top: 20px;
          color: rgba(0, 120, 209, 1);
          font-size: 20px;
          line-height: 26px;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }

  a.reference-link {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #ffffff;
    background: none;
    margin: 0;
    padding: 0;
    display: inline;
  }

  a.features-link {
    border-bottom: 2px solid #ffffff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  a.explore-docs-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin-top: 70px;
    padding: 15px 45px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    border-radius: 100px;
    color: #096ef2;
    border: none;
    background: #ffffff;
  }
}

a.usecases-link {
  border-bottom: 1px solid #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .use-cases {
    margin-top: 50px;
    padding: 50px;

    .use-case-list {
      .use-case-block {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .examples-block {
      .examples-block-list {
        flex-wrap: wrap;

        .examples-unit-block {
          width: calc(100% / 4);

          .image {
            width: 75px;
            height: 75px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .more-use-cases {
      margin: 50px 0 0 0;

      .more-use-cases-list {
        .more-use-cases-block {
          margin: 0 15px;

          img {
            max-height: 40px;
            height: unset;
          }

          label {
            text-align: center;
          }
        }
      }
    }

    a.explore-docs-btn {
      margin-top: 50px;
      padding: 15px 40px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .use-cases {
    margin-top: 50px;
    padding: 50px 30px;
    border-radius: unset;

    h2 {
      text-align: left;
      width: 100%;
    }

    p.tagline {
      width: 100%;
      text-align: left;
    }

    .use-case-list {
      margin-top: 0px;
      flex-flow: column;
      width: 100%;

      .use-case-block {
        width: 100%;
        margin-top: 40px;
        padding: 20px;
        align-items: flex-start;
        justify-content: center;
        padding-right: 0;
        padding-left: 0;

        img {
          width: 40px;
        }

        h4 {
          margin: 0;
          margin-left: 25px;
        }

        label.coming-soon {
          position: absolute;
          right: 0;
          top: 20px;
          margin-top: 0;
        }
      }
    }

    .examples-block {
      width: 100%;

      .examples-block-list {
        flex-wrap: wrap;

        .examples-unit-block {
          width: calc(100% / 3);

          .image {
            width: 75px;
            height: 75px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .more-use-cases {
      margin: 50px 0 0 0;

      .more-use-cases-list {
        .more-use-cases-block {
          width: calc(100% / 7);
          margin: 30px 0 0 0;

          label {
            margin: 15px 0 0 0;
          }
        }
      }
    }

    a {
      margin-top: 50px;
      padding: 15px 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .use-cases {
    .more-use-cases {
      .more-use-cases-list {
        flex-flow: row;
        justify-content: space-around;
        flex-wrap: wrap;

        .more-use-cases-block {
          width: calc(100% / 3 - 45px);
        }
      }
    }
  }
}

@media screen and (max-width: 710px) {
  .use-cases {
    h2,
    p.tagline {
      text-align: left;
      align-self: flex-start;
    }

    .use-case-list {
      .use-case-block {
        align-items: flex-start;

        h4,
        p {
          text-align: left;
        }
      }
    }

    .examples-block {
      .examples-block-list {
        .examples-unit-block {
          .image {
            width: 50px;
            height: 50px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .more-use-cases {
      p {
        text-align: left;
      }

      .more-use-cases-list {
        flex-flow: row;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-around;

        .more-use-cases-block {
          align-items: center;
          width: calc(50% - 45px);
          padding: 15px;

          img {
            width: 50px;
          }

          label {
            font-size: 14px;
            line-height: 20px;
            margin: 15px 0 0 0;
          }
        }
      }
    }

    a.explore-docs-btn {
      align-self: flex-start;
      font-size: 13px;
      font-weight: 600;
    }
  }
}
