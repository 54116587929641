.site-footer {
	display: flex;
	flex-flow: column;
	position: relative;
	margin-top: 100px;

	.footer-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #FAFAFA;
		border-radius: 100px 100px 0 0;
		padding: 30px 60px 30px 60px;
		width: 100%;
		box-sizing: border-box;
		padding-top: 0;

		.footer-logo {
			width: 20%;
			z-index: 1;
		}

		.footer-links {
			z-index: 1;
		}

		.footer-logo {
			img.decentro-d {
				width: 170px;
				margin-top: 55px;
				margin-left: 50px;
				cursor: pointer;
			}
		}

		.footer-links-section {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 50px;
			margin-top: 50px;
			margin-right: 50px;

			.footer-links {

				h3 {
					font-size: 20px;
					line-height: 26px;
					font-weight: 600;
					margin: 0 0 10px 0;
				}

				>a {
					font-size: 16px;
					line-height: 24px;
					font-weight: 500;
					color: #3C3C3C;
					text-decoration: none;
					display: flex;
					align-items: center;

					>label.badge {
						font-size: 8px;
						line-height: 18px;
						font-weight: 600;
						background: transparent linear-gradient(194deg, #66CEAC 0%, #3A36C6 100%);
						border-radius: 25px;
						color: #fff;
						padding: 0px 5px;
						margin-left: 10px;
						cursor: pointer;
					}
				}
			}
		}


		.cert-footer-section {
			margin-right: 35px;

			.backing-and-certificate {
				display: flex;
				flex-flow: row;
				align-items: flex-start;
				justify-content: space-between;

				img.iso-certificate {
					max-width: 85px;
				}
			}
		}
	}

	.copyright {
		display: flex;
		flex-flow: row;
		align-items: center;
		justify-content: space-between;
		height: 90px;
		background: #F0F0F0;
		z-index: 1;
		padding: 0 50px;

		p {
			display: flex;
			align-items: center;
			color: #354052;
			font-size: 13px;
			font-weight: 400;

			img {
				margin-left: 10px;
			}
		}

		.contact-emails {
			display: flex;
			flex-flow: row;
			justify-content: space-between;
			width: 100%;

			p.copyright-text {
				width: 40%;
			}

			.contacts {
				display: flex;
				flex-flow: row;
				width: 60%;
				justify-content: space-between;

				.email-block {
					display: flex;
					flex-flow: row;
					align-items: center;
					justify-content: center;

					.email-label {
						font-size: 13px;
						font-weight: 400;
						line-height: 24px;
					}

					label.email {
						display: inline-block;
						font-weight: 600;
						font-size: 13px;
						line-height: 24px;
						color: #3C3C3C;
						text-decoration: underline;
						margin-left: 7px;

						a {
							color: inherit;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1280px) and (min-width: 1024px) {
	.site-footer {
		img.decentro-d-transparent {
			width: 485px;
			bottom: 10px;
		}

		.footer-top {
			padding: 30px;
			padding-top: 0;

			.footer-links-section {
				gap: 30px;
				margin-top: 35px;
				margin-right: 35px;

				.footer-links {
					>a {
						font-size: 15px;
						margin-top: 10px;

						>label.badge {}
					}

					h3 {
						font-size: 18px;
					}

					label {
						font-size: 16px;
					}

					.backing-and-certificate {
						img.iso-certificate {
							width: 45px;
						}

						p {
							img {
								width: 85px;
							}
						}

						img.iso-certificate {
							width: 45px;
						}
					}
				}
			}

			.footer-logo {
				img.decentro-d {
					width: 150px;
				}
			}
		}

		.copyright {
			height: 110px;

			.contact-emails {
				.contacts {
					flex-flow: column;
					align-items: flex-end;
					justify-content: center;
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.site-footer {
		.footer-top {
			padding: 30px;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			border-radius: unset;

			.footer-logo {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img.decentro-d {
					width: 140px;
					margin: 30px 0 10px 0;
				}
			}

			.footer-links-section {
				flex-flow: column;
				margin: 0 auto;
				grid-gap: 0;
				margin-top: 30px;

				.footer-links {
					width: 100%;
					align-items: center;
					margin-top: 0;
					width: auto;

					>a {
						font-size: 16px;
						margin-top: 20px;
						line-height: 20px;
					}

					.contact-emails {
						display: flex;
						flex-flow: column;
						align-items: center;
						justify-content: center;

						.email-block {
							display: flex;
							flex-flow: column;
							align-items: center;
							justify-content: center;

							.email-label {
								text-align: center;
							}

							label.email {
								text-align: center;
							}
						}
					}

					h3 {
						font-size: 18px;
						margin: 0;
					}
				}

				.footer-links:first-child {
					a {
						margin-top: 0;
					}
				}
			}
		}

		.copyright {
			padding: 30px;
			height: unset;
			flex-flow: column;
			justify-content: center;

			.contact-emails {
				flex-flow: column-reverse;
				justify-content: center;
				align-items: center;

				p.copyright-text {
					margin-top: 20px;
					width: auto;
					text-align: center;
				}

				.contacts {
					flex-flow: column;
					width: auto;
				}
			}
		}

		img.decentro-d-transparent {
			display: none;
		}

		.cert-footer-section {
			margin-right: 0 !important;
			margin-top: 20px !important;

			.backing-and-certificate {
				img.iso-certificate {
					width: 60px;
				}
			}
		}
	}
}

@media screen and (max-width: 601px) {
	.site-footer {
		.copyright {
			.contact-emails {
				.contacts {
					.email-block {
						flex-flow: column;
						align-items: center;
						justify-content: center;
						margin-top: 10px;
						margin-right: 0;
					}
				}
			}
		}
	}
}