.text-and-button-banner {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	padding: 80px 100px;

	p {
		color: #0092FF;
		font-size: 30px;
		line-height: 40px;
		font-weight: 400;
		text-align: center;

		span {
			font-weight: 600;
			color: #000000;
		}

		a.reference-link {
			text-decoration: none;
			color: rgba(0, 120, 209, 1);
			font-size: 30px;
			line-height: 40px;
			font-weight: 400;
			display: inline;
			background: none;
			padding: 0;
			margin: 0;
		}
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: max-content;
		margin-top: 40px;
		padding: 15px 45px;
		font-size: 20px;
		font-weight: 600;
		line-height: 26px;
		border-radius: 100px;
		color: #ffffff;
		border: none;
		background: transparent linear-gradient(270deg, #00C4FF 0%, #0486EF 48%, #0842DD 100%) 0% 0%;
	}

}

@media screen and (max-width: 1280px) and (min-width: 1024px) {
	.text-and-button-banner {
		padding: 50px;

		p {
			font-size: 24px;
			line-height: 36px;

			a.reference-link {
				font-size: 24px;
				line-height: 36px;
			}
		}

		button {
			padding: 15px 40px;
			font-size: 18px;
			margin-top: 30px;
		}
	}
}

@media screen and (max-width: 1023px) {
	.text-and-button-banner {
		padding: 30px;

		p {
			font-size: 24px;
			line-height: 30px;

			a.reference-link {
				font-size: 24px;
				line-height: 36px;
			}
		}

		button {
			margin-top: 20px;
			padding: 15px 30px;
			font-size: 18px;
		}
	}
}

@media screen and (max-width: 710px) {
	.text-and-button-banner {
		p {
			// text-align: left;
			// align-self: flex-start;
		}

		a {
			// align-self: flex-start;
			font-size: 18px;
			font-weight: 500;
			padding: 10px 45px;
		}
	}
}