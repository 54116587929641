.master-services-agreement {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60px 100px;

    h2 {
        font-size: 36px;
        line-height: 44px;
        font-weight: 600;
        color: #000000;
    }

    p {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        color: #8B8B8B;
    }

    .content {
        margin-top: 10px;

        h4 {
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px;
            color: #000000;
        }

        ol {
            padding: 0 0 0 30px;
            margin: 10px 0 0 0;

            li {
                // list-style: auto;
                margin: 7px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #000000;
            }
        }

        p {
            margin: 10px 0 0 0;
            padding: 0;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #000000;
        }

        ol.order-level-1 {
            list-style-type: upper-alpha;
        }

        ol.order-level-3 {
            list-style-type: lower-alpha;
        }

        ol.order-level-2 {
            list-style-type: none;
            counter-reset: item;
            margin-left: 25px;
            padding: 0;
        }

        ol.order-level-11 {
            list-style-type: auto;
            counter-reset: item;
            margin-left: 25px;
            padding: 0;
        }

        ol.order-level-11>li {
            counter-increment: item;
        }

        ol.order-level-2>li {
            display: table;
            counter-increment: item;
            margin-bottom: 0.6em;
        }

        ol.order-level-2>li:before {
            content: counters(item, ".") ". ";
            display: table-cell;
            padding-right: 0.6em;
        }

        li ol.order-level-2>li {
            margin: 7px 0;
        }

        li ol.order-level-2>li:before {
            content: counters(item, ".") " ";
        }

        ol.order-level-4 {
            list-style-type: lower-roman;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
}

@media screen and (max-width: 1280px) {
    .documents-container {
        .document-type-list {
            width: 100%;
        }

        .document-content {
            width: 100%;

            .master-services-agreement {
                padding: 60px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .documents-container {
        .document-type-list {
            a {
                padding-left: 25px;
                padding-right: 25px;
            }
        }

        .document-content {
            width: 100%;

            .master-services-agreement {
                padding: 60px 0px;
            }
        }
    }
}

@media screen and (max-width: 710px) {
    .documents-container {
        .document-type-list {
            a {
                padding-left: 20px;
                padding-right: 20px;
                font-size: 14px;
            }
        }
    }

}

@media screen and (max-width: 425px) {
    .documents-container {
        padding: 60px 30px;

        .document-content {
            width: 100%;

            .master-services-agreement {
                padding: 60px 0px;
            }
        }
    }
}