.enach-details {
  .inline-image-and-paragraph {
    .paragraph {
      p {
        font-size: 16px;
      }

      .inline-image-text-bullet-points {
        .inline-image-text-bullet {
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          margin-top: 20px;

          span {
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            margin-top: 20px;
          }

          &::before {
            top: 5px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .enach-details {
    .inline-image-and-paragraph {
      .paragraph {
        .inline-image-text-bullet-points {
          .inline-image-text-bullet {
            font-size: 18px;
            line-height: 28px;

            span {
              font-size: 18px;
              line-height: 28px;
            }

            &::before {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
  .enach-apis-applications-content {
    .inline-image-and-paragraph {
      padding: 30px;

      h2 {
        font-size: 24px;
        line-height: 30px;
      }

      p {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
      }

      .image {
        width: 35%;
      }

      .paragraph {
        width: 55%;
      }
    }

    .inline-image-and-paragraph:nth-child(n) {
      .zoom-feature {
        padding-left: 30px;
        padding-right: 0;
      }
    }

    .inline-image-and-paragraph:nth-child(2n) {
      padding-right: 30px;
      padding-left: 0;
    }

    .inline-image-and-paragraph:nth-child(2),
    .inline-image-and-paragraph:nth-child(3),
    .inline-image-and-paragraph:nth-child(4),
    .inline-image-and-paragraph:nth-child(5) {
      .zoom-feature {
        width: 35%;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .enach-details {
    .inline-image-and-paragraph {
      .paragraph {
        p {
          font-size: 16px;
        }

        .inline-image-text-bullet-points {
          .inline-image-text-bullet {
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            margin-top: 20px;

            span {
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              margin-top: 20px;
            }

            &::before {
              top: 5px;
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
  .commonly-asked-questions {
    padding: 50px;
    h2 {
      width: 100%;
      text-align: left;
    }

    .accordion-list {
      width: 100%;
    }
  }
  .enach-apis-applications-content {
    margin-top: 40px;
    .inline-image-and-paragraph {
      padding: 20px;
      margin-top: 30px;
      flex-flow: column !important;

      .image {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          max-width: 200px;
        }
      }

      .paragraph {
        justify-content: flex-start;

        h2 {
          color: #3c3c3c;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          margin-top: 20px;
          text-align: center;
        }

        p {
          margin-top: 10px;
          font-size: 16px;
          line-height: 26px;
          text-align: center;
        }
      }
    }

    .inline-image-and-paragraph:nth-child(n) {
      .image {
        width: 100%;
      }

      div.zoom-feature {
        position: relative;
        left: unset;
        right: unset;
        width: 100%;
        text-align: center;
        padding: 0;
      }
    }

    .inline-image-and-paragraph:nth-child(2),
    .inline-image-and-paragraph:nth-child(5) {
      img {
        max-width: 150px;
      }
    }
  }
}

@media screen and (max-width: 710px) {
  .enach-apis-applications-content {
    .inline-image-and-paragraph {
      .image {
        img {
          max-width: 200px;
        }
      }

      .paragraph {
        h2,
        p {
          text-align: left !important;
          align-self: flex-start;
        }
      }
    }
  }

  .commonly-asked-questions {
    padding: 30px;
    .accordion-list {
    }
  }
}

.enach-apis-applications {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 80px 100px;

  h2 {
    // font-size: $product-sections-header-font-size-desktop;
    // line-height: $product-sections-header-line-height-desktop;
    font-weight: 400;
    color: #096ef2;

    span {
      font-weight: 600;
    }
  }

  p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: #3c3c3c;
    margin-top: 30px;
    width: 65%;
    text-align: center;
  }

  .enach-apis-applications-content {
    .inline-image-and-paragraph {
      padding: 60px;
      position: relative;

      h2 {
        color: #3c3c3c;
        font-size: 28px;
        font-weight: 600;
        line-height: 34px;
      }

      p {
        margin-top: 32px;
        font-size: 20px;
        line-height: 36px;
        text-align: left;
        width: 100%;
      }

      &:nth-child(2n) {
        flex-flow: row-reverse;
      }

      .image {
        align-items: center;

        img {
          width: 100%;
        }
      }

      .paragraph {
        justify-content: center;
      }

      .zoom-feature {
        position: absolute;
        bottom: 0;
        box-sizing: border-box;

        .explanatory-image-enlarged {
          width: 100%;
          height: 100%;
          overflow-y: scroll;

          img {
            width: 75%;
          }
        }
      }
    }

    .inline-image-and-paragraph:nth-child(1) {
      .image {
        align-items: center;
        justify-content: center;
        width: 30%;

        img {
          width: 100%;
        }
      }

      .zoom-feature {
        width: 45%;
        text-align: center;
        left: 0;
        padding-left: 60px;
      }
    }

    .inline-image-and-paragraph:nth-child(2) {
      .image {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;

        img {
          width: 60%;
        }
      }

      .zoom-feature {
        width: 40%;
        right: 0;
        left: unset;
        text-align: center;
        padding-right: 60px;

        .explanatory-image-enlarged {
          img {
            width: fit-content;
          }
        }
      }
    }

    .inline-image-and-paragraph:nth-child(3) {
      .image {
        width: 25%;
      }

      .zoom-feature {
        width: 40%;
        left: 0;
        right: unset;
        text-align: center;
        padding-left: 60px;
        padding-right: 0;
      }
    }

    .inline-image-and-paragraph:nth-child(4) {
      .image {
        width: 31%;
      }

      .zoom-feature {
        width: 40%;
        right: 0;
        left: unset;
        text-align: center;
        padding-right: 60px;
        padding-left: 0;
      }
    }

    .inline-image-and-paragraph:nth-child(5) {
      .image {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 60%;
        }
      }

      .zoom-feature {
        width: 40%;
        left: 0;
        right: unset;
        text-align: center;
        padding-left: 60px;

        .explanatory-image-enlarged {
          img {
            width: fit-content;
          }
        }
      }
    }

    .inline-image-and-paragraph:nth-child(6) {
      .image {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 65%;
        }
      }

      .zoom-feature {
        width: 40%;
        left: 0;
        right: unset;
        text-align: center;
        padding-left: 60px;

        .explanatory-image-enlarged {
          img {
            width: fit-content;
          }
        }
      }
    }
  }
}

.special-info-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 80px 100px;

  .special-info-wrapper {
    border-radius: 40px;
    background-color: #f7faff;
    padding: 50px 100px;
    width: 70%;

    h2 {
      //   font-size: $product-sections-header-font-size-desktop;
      //   line-height: $product-sections-header-line-height-desktop;
      font-weight: 400;
      color: #096ef2;
      text-align: center;

      span {
        font-weight: 600;
      }
    }

    p.special-info-description {
      text-align: center;
      margin-top: 40px;
      //   font-size: $product-pages-body-text-font-size-desktop;
      //   line-height: $product-pages-body-text-line-height-desktop;
      word-wrap: break-word;
    }
  }
}

.commonly-asked-questions {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 50px 85px;
  gap: 40px;
  h2 {
    // font-size: $product-sections-header-font-size-desktop;
    // line-height: $product-sections-header-line-height-desktop;
    font-weight: 400;
    color: #096ef2;
    span {
      font-weight: 600;
    }
  }
  .accordion-list {
    width: 80%;
  }
}
