// @import "../../../utilities/_variables.scss";

.table-of-contents {
  padding: 50px 100px;
  .table-contents-wrapper {
    border: 1px solid #b7b7b7;

    padding: 50px;
    border-radius: 10px;
    h1.table-header {
      //   font-size: $product-sections-subheader-font-size-desktop;
      //   line-height: $product-sections-subheader-line-height-desktop;
      color: #0a192c;
      text-align: left;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
    .table-contents {
      position: relative;
      margin-top: 40px;
      li {
        display: flex;
        flex-flow: row;
        align-items: center;
        color: #096ef2;
        text-align: left;
        cursor: pointer;
        font-weight: 600;
        margin: 15px 0;
        &::before {
          content: "";
          background: linear-gradient(240deg, #0092ff 0%, #1656db 100%) 0% 0%
            no-repeat padding-box;
          border-radius: 50px;
          position: absolute;
          z-index: 1;
        }
      }
      .table-content-level-1 {
        margin-left: 20px;
        // font-size: $product-pages-body-text-font-size-desktop;
        // line-height: $product-pages-body-text-line-height-desktop;

        width: fit-content;
        &::before {
          width: 6px;
          height: 6px;
          left: 0;
        }
      }
      .table-content-level-1:last-child {
        margin-bottom: 0;
      }
      .table-content-level-2 {
        margin-left: 60px;
        font-size: 16px;
        line-height: 40px;
        &::before {
          width: 10px;
          height: 10px;
          left: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .table-of-contents {
    padding: 50px;
    .table-contents-wrapper {
      padding: 50px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .table-of-contents {
    padding: 50px 30px;
    .table-contents-wrapper {
      padding: 30px;
    }
  }
}
