.documents-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 60px 100px;
  border-top: 1px solid #e2dfdf;

  .document-type-list {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    a {
      background: #f8f8f8;
      border-radius: 100px;
      padding: 15px 40px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: #000000;
      text-align: center;
    }

    a.active {
      background: #eff5ff;
    }
  }

  .document-content {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;

    .scroll-to-top-btn {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: auto;
      bottom: 60px;
      // padding-top: 800px;
      margin-top: 800px;

      button {
        position: sticky;
        background: #f8f8f8;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        border: none;
        bottom: 60px;
      }

      svg {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .documents-container {
    .document-type-list {
      a {
        padding: 15px 25px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .documents-container {
    .document-type-list {
      grid-template-columns: repeat(2, 1fr);
      width: 70%;
      align-self: flex-start;

      a {
        padding: 10px 25px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .document-content {
      width: 100%;
    }
  }
}

@media screen and (max-width: 900px) {
  .documents-container {
    padding: 60px 40px;

    .document-type-list {
      width: 90%;

      a {
        padding: 10px 20px;
      }
    }
  }
}

@media screen and (max-width: 715px) {
  .documents-container {
    .document-type-list {
      width: 100%;
    }
  }
}

@media screen and (max-width: 715px) {
  .documents-container {
    .document-type-list {
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      align-self: flex-start;
    }
  }
}

@media screen and (max-width: 400px) {
  .documents-container {
    .document-type-list {
      width: 100%;
    }

    .document-content {
      table {
        font-size: 12px;

        td {
          p {
            font-size: 12px;
          }
        }
      }

      ol.order-level-11 {
        margin-left: 0px !important;
      }

      ol.order-level-2 {
        margin-left: 0 !important;
      }

      ol.order-level-3 {
        padding-left: 10px !important;
      }
    }
  }
}
