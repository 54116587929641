.homepage {
    display: flex;
    flex-flow: column;

    .banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 100px;
        padding-bottom: 30px;
        padding-right: 100px;
        margin: 150px 0 20px 0;

        img.banner-bg {
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 100%;
            max-width: 925px;
        }

        .text-content {
            display: flex;
            flex-flow: column;

            h1 {
                font-size: 36px;
                font-weight: 400;
                line-height: 56px;
                color: #000000;

                span {
                    font-size: 52px;
                    font-weight: 600;
                    color: #0092FF;
                }
            }

            h2 {
                font-size: 24px;
                font-weight: 400;
                line-height: 36px;
                color: #7E7E7E;
                margin-top: 30px;
                width: 80%;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: max-content;
                margin-top: 50px;
                padding: 15px 45px;
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                border-radius: 100px;
                color: #ffffff;
                border: none;
                background: transparent linear-gradient(270deg, #00C4FF 0%, #0486EF 48%, #0842DD 100%) 0% 0%;
            }
        }

        .image-content {
            margin-top: -175px;
            margin-right: 100px;

            img {
                width: 700px;
            }
        }
    }

    .what-is-decpay {
        .inline-image-and-paragraph {
            .paragraph {
                h2 {
                    color: #000000;

                    span {
                        color: #0092FF;
                    }
                }
            }
        }
    }

    .our-products {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 80px 100px;

        h2 {
            font-size: 40px;
            line-height: 50px;
            font-weight: 400;
            color: #000000;

            span {
                color: #0092FF;
                font-weight: 600;
            }
        }

        .our-products-content {
            width: 100%;
            display: flex;
            flex-flow: row;
            align-items: flex-start;
            justify-content: center;
            position: relative;
            margin-top: 80px;

            hr {
                width: 1px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 320px;
                margin-top: 0;
                border: none;
                background: #bcbcbc;
            }

            .product {
                width: 45%;

                .product-title {
                    color: #0092FF;
                    font-size: 30px;
                    font-weight: 600;
                    margin-bottom: 40px;
                    text-align: center;
                }

                .virtual-accounts-pointers {
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 25px;
                        border-left: 2px dashed #ccc;
                        z-index: -1;
                        top: 0;
                        bottom: 0;
                        -webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
                        clip-path: polygon(100% 50%, 0 0, 0 100%);
                    }
                }

                .payment-collections-pointers {
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 25px;
                        border-left: 2px dashed #ccc;
                        z-index: -1;
                        top: 0;
                        bottom: 0;
                        -webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
                        clip-path: polygon(100% 50%, 0 0, 0 100%);
                    }
                }

                .products-pointer {
                    display: flex;
                    flex-flow: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                    min-height: 140px;
                    max-height: 150px;
                    padding-bottom: 50px;

                    .step-icon {
                        display: flex;
                        flex-flow: row;
                        align-items: center;
                        justify-content: center;
                        margin-right: 20px;

                        .step-icon-img {
                            width: 50px;
                            height: 50px;
                        }
                    }

                    .products-pointer-content {
                        display: flex;
                        flex-flow: column;
                        align-items: flex-start;
                        justify-content: flex-start;

                        .point-title {
                            color: #707070;
                            font-size: 30px;
                            line-height: 36px;
                            text-align: left;
                            font-weight: 600;
                        }

                        .point-content {
                            color: #707070;
                            font-size: 24px;
                            line-height: 36px;
                        }
                    }
                }
            }
        }
    }

    .get-started {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 50px 100px;

        h2 {
            font-size: 40px;
            line-height: 50px;
            font-weight: 400;
            color: #000000;

            span {
                color: #0092FF;
                font-weight: 600;
            }
        }

        p.tagline {
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
            color: #3C3C3C;
            margin-top: 30px;

        }

        .get-started-steps {
            display: flex;
            flex-flow: row;
            align-items: stretch;
            justify-content: space-between;
            margin-top: 80px;
            width: 85%;
            position: relative;

            .step-block {
                position: relative;
                width: 20%;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 30px;

                svg {
                    font-size: 50px;
                    color: #3C3C3C;
                }

                h4 {
                    margin-top: 30px;
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 600;
                    color: #0092FF;
                }

                p {
                    margin-top: 20px;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    color: #3C3C3C;
                    text-align: center;
                }
            }

            .step-arrow {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: center;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            img.process-line1 {
                position: absolute;
                width: 275px;
                height: 56px;
                top: -40px;
                left: 21%;
            }

            img.process-line2 {
                position: absolute;
                width: 275px;
                height: 56px;
                top: -40px;
                left: 56%;
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: max-content;
            margin-top: 80px;
            padding: 15px 45px;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            border-radius: 100px;
            color: #ffffff;
            border: none;
            background: transparent linear-gradient(90deg, #3A36C6 0%, #66CEAC 100%) 0% 0%;
        }
    }
}

.internal-error-box {
    border-radius: 10px;
    border: none !important;
    min-width: 320px;
    max-width: 440px;
    width: 70% !important;
    padding: 45px !important;
    outline: none;

    .server-error-message {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        img {
            width: 50px;
        }

        p {
            margin-top: 30px;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 1600px) {
    .homepage {
        .banner {

            img.banner-bg {
                max-width: 865px;
            }

            .image-content {
                margin-right: 80px;

                img {
                    width: 640px;
                }
            }
        }

        .our-products {
            .our-products-content {

                .product {
                    .product-title {
                        font-size: 28px;
                    }

                    .products-pointer {

                        .products-pointer-content {

                            .point-title {
                                font-size: 28px;
                            }

                            .point-content {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .homepage {
        .banner {
            .text-content {
                h1 {
                    font-size: 34px;

                    span {
                        font-size: 50px;
                    }
                }
            }

            img.banner-bg {
                max-width: 805px;
            }

            .image-content {
                margin-right: 40px;

                img {
                    width: 615px;
                }
            }
        }

        .our-products {
            padding: 80px 85px;

            .our-products-content {

                .product {
                    .product-title {
                        font-size: 26px;
                    }

                    .products-pointer {
                        min-height: 180px;
                        max-height: none;
                        padding-bottom: 40px;

                        .products-pointer-content {

                            .point-title {
                                font-size: 26px;
                            }

                            .point-content {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1280px) and (min-width: 1024px) {
    .homepage {
        .banner {
            padding: 0 50px;
            margin: 100px 0;

            img.banner-bg {
                width: 670px;
            }

            .text-content {
                h1 {
                    font-size: 36px;
                    line-height: 50px;

                    span {
                        font-size: 48px;
                    }
                }

                h2 {
                    font-size: 18px;
                    line-height: 26px;
                    margin-top: 20px;
                }

                a {
                    margin-top: 30px;
                    padding: 15px 40px;
                    font-size: 18px;
                }
            }

            .image-content {
                margin-top: -70px;

                img {
                    width: 460px;
                }
            }
        }

        .our-products,
        .get-started {
            padding: 50px;

            h2 {
                font-size: 36px;
                line-height: 42px;
                text-align: center;
            }
        }

        .get-started {
            p.tagline {
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                margin-top: 20px;
            }

            .get-started-steps {
                width: 100%;
            }
        }

        .our-products {
            .our-products-content {
                .product {
                    .product-title {
                        font-size: 24px;
                    }

                    .products-pointer {
                        .products-pointer-content {
                            .point-title {
                                font-size: 24px;
                            }

                            .point-content {
                                line-height: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .homepage {
        .banner {
            padding: 0 30px;
            flex-flow: column-reverse;
            justify-content: unset;
            min-height: unset;
            margin: 20px 0 50px 0;

            img.banner-bg {
                display: none;
            }

            .text-content {
                margin-top: 30px;
                align-items: center;
                justify-content: center;

                h1 {
                    text-align: center;
                    font-size: 32px;
                    line-height: 44px;
                }

                h2 {
                    text-align: center;
                    font-size: 18px;
                    margin-top: 20px;
                }

                a {
                    margin-top: 30px;
                    font-size: 18px;
                    padding: 10px 35px;
                }
            }

            .image-content {
                margin-top: 75px;
                text-align: center;

                img {
                    width: 250px;
                }
            }
        }

        .our-products,
        .get-started {
            padding: 30px;

            h2 {
                font-size: 30px;
                line-height: 40px;
                text-align: center;
            }
        }

        .get-started {
            p.tagline {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                margin-top: 20px;
            }

            .get-started-steps {
                margin-top: 0;
                flex-flow: column;
                align-items: center;
                justify-content: center;

                .step-arrow {
                    margin: 20px 0;

                    img {
                        transform: rotate(90deg);
                        width: 35px;
                        height: 35px;
                    }
                }

                .step-block {
                    width: 90%;

                    h4 {
                        font-size: 20px;
                        margin-top: 20px;
                    }

                    p {
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 20px;

                    }
                }

                .step-block:first-child {
                    margin-top: 40px;
                }

            }
        }

        .our-products {
            .our-products-content {
                .product {
                    .product-title {
                        font-size: 22px;
                    }

                    .products-pointer {

                        .products-pointer-content {
                            .point-title {
                                font-size: 22px;
                                line-height: 30px;
                            }

                            .point-content {
                                font-size: 18px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 710px) {
    .homepage {
        .banner {
            .text-content {
                align-items: flex-start;
                width: 100%;

                h1,
                h2 {
                    text-align: left;
                }

                h1 {
                    span {
                        font-size: 40px;
                    }
                }
            }

            .image-content {
                img {
                    width: 75%;
                }
            }
        }

        .our-products,
        .get-started {

            h2,
            p.tagline {
                text-align: left;
                align-items: flex-start;
                width: 100%;
            }

            .our-products-content {
                flex-flow: column;
                margin-top: 0;

                .product {
                    width: 100%;
                    margin-top: 30px;

                    .product-title {
                        text-align: left;

                    }

                    .products-pointer {
                        min-height: 0;
                    }
                }

                hr {
                    height: 2px !important;
                    width: 100%;
                    margin: 10px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 601px) {
    .internal-error-box {
        min-width: unset;
        max-width: 320px;
        width: 70% !important;
        padding: 25px !important;

        .server-error-message {
            img {
                width: 30px;
            }

            p {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}