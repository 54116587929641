header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px 90px;
	background: transparent;

	.menu-full {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.left {
			display: flex;
			align-items: center;

			img {
				width: 180px;
			}

			.header-logo-subheader {
				display: flex;
				flex-flow: row;
				align-items: center;
				justify-content: space-between;

				p {
					display: none;
					font-size: 42px;
					line-height: 52px;
					margin-left: 40px;
					text-align: left;
					border-left: 1px solid #000000;
					padding: 0 40px;
					color: #0092FF;
				}
			}
		}

		.right {
			ul {
				display: flex;
				align-items: center;

				li {
					margin: 0 20px;
					font-size: 18px;
					font-weight: 500;
					color: #000000;
					cursor: pointer;

					&:first-child {
						margin-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}

					a {
						font-size: 18px;
						display: flex;
						align-items: center;
						justify-content: center;
						width: max-content;
						padding: 15px 45px;
						font-size: 18px;
						font-weight: 600;
						line-height: 26px;
						border-radius: 100px;
						color: #000000;
						border: none;
						background: #FFFFFF;
					}

					span {
						display: flex;
						align-items: center;

						svg {
							font-size: 14px;
							margin-left: 10px;
							margin-top: 2px;
						}
					}
				}

				li.dropdown-menu {
					display: flex;
					flex-flow: column;
					position: relative;

					ul.dropdown {
						display: none;
						flex-flow: column;
						align-items: flex-start;
						justify-content: flex-start;
						position: absolute;
						width: max-content;
						right: -40px;
						top: 40px;
						padding: 40px;
						border-radius: 26px;
						background: #fff;
						box-shadow: 0px 10px 20px rgba(60, 60, 60, 0.1);

						li {
							margin: 10px 0;
							text-align: left;
							font-size: 17px;
							font-weight: 400;
							color: rgba(60, 60, 60, 1);

							a {
								font-size: 17px;
								font-weight: 400;
								color: rgba(60, 60, 60, 1);
							}
						}

						li:first-child {
							margin: 0 0 10px 0;
						}

						li:last-child {
							margin: 10px 0 0 0;
						}
					}

					ul.dropdown:before {
						content: "";
						position: absolute;
						right: 30px;
						top: -15px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 15px 15px 15px;
						border-color: transparent transparent #ffffff transparent;
						z-index: 9999;
					}

					ul.dropdown.show {
						display: flex;
						background: #FFF;
						z-index: 100;
					}
				}
			}

			ul.accounts-submenu {
				display: flex;
				flex-flow: column;
				align-items: flex-start;
				padding-left: 20px;

				li {
					font-size: 15px !important;
					margin: 10px 0 !important;

					a {
						font-size: 15px !important;
					}
				}
			}

			ul.dark {

				li,
				li a {
					color: rgba(60, 60, 60, 1) !important;
				}
			}
		}
	}

	ul {
		list-style-type: none;
	}
}

@media screen and (max-width: 1450px) {
	header {
		.menu-full {
			.left {
				.header-logo-subheader {
					p {
						font-size: 34px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1380px) and (min-width: 1300px) {
	header {
		.menu-full {
			.left {
				.header-logo-subheader {
					p {
						display: none;
						font-size: 30px;
						line-height: 44px;
						margin-left: 25px;
						padding: 0 25px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) and (min-width: 1281px) {
	header {
		.menu-full {
			.left {
				.header-logo-subheader {
					p {
						display: none;
						font-size: 27px;
						line-height: 44px;
						margin-left: 25px;
						padding: 0 25px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1280px) and (min-width: 1024px) {
	header {
		padding: 40px;

		.menu-full {
			.left {
				img {
					width: 200px;
				}

				.header-logo-subheader {
					p {
						display: none;
						font-size: 32px;
						line-height: 52px;
						margin-left: 30px;
						padding: 0 20px;
					}
				}
			}

			.right {
				ul {
					li {
						margin: 0 10px;
						font-size: 16px;

						span {
							svg {
								font-size: 13px;
								margin-left: 5px;
							}
						}
					}

					li.dropdown-menu {
						ul.dropdown {
							li {
								font-size: 16px;
								margin: 5px 0;

								a {
									font-size: 16px;
								}

								&:first-child {
									margin: 5px 0;
								}

								&:last-child {
									margin: 5px 0;
								}
							}

							&.show {
								display: flex;
								padding: 30px;
								right: -38px;
							}
						}
					}
				}

				ul.accounts-submenu {
					li {
						margin: 5px 0 !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1057px) and (min-width: 1024px) {
	header {
		.menu-full {
			.left {
				.header-logo-subheader {
					p {
						display: none;
						font-size: 28px;
						line-height: 52px;
						margin-left: 20px;
						padding: 0 20px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	header {
		padding: 40px;

		.menu-full {
			.left {
				img {
					width: 200px;
				}

				.header-logo-subheader {
					p {
						display: none;
						font-size: 24px;
						line-height: 46px;
						margin-left: 20px;
						padding: 0 20px;
					}
				}
			}

			.right {
				ul {
					li {
						font-size: 16px;

						a {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}


@media screen and (max-width: 993px) {
	header {
		padding: 40px;

		.menu-full {
			.left {
				.header-logo-subheader {
					p {
						display: none;
						font-size: 20px;
						line-height: 44px;
						margin-left: 15px;
						padding: 0 15px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 950px) {
	header {
		padding: 40px;

		.menu-full {
			.left {
				.header-logo-subheader {
					p {
						display: none;
						font-size: 18px;
						line-height: 44px;
						margin-left: 13px;
						padding: 0 13px;
					}
				}
			}
		}
	}
}