.privacy-policy{
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 60px 100px;
	h2{
		font-size: 36px;
		line-height: 44px;
		font-weight: 600;
		color: #000000;
	}
	p{
		font-size: 18px;
		line-height: 26px;
		font-weight: 400;
		color: #8B8B8B;
	}
	.content{
		margin-top: 10px;
		h4{
			font-size: 20px;
			font-weight: 600;
			margin-top: 20px;
			color: #000000;
		}
		ol{
			padding: 0 0 0 30px;
			margin: 10px 0 0 0;
			li{
				list-style: auto;
				margin: 7px;
				font-size: 16px;
				line-height: 22px;
				font-weight: 400;
				color: #000000;
			}
		}
		p{
			margin: 10px 0 0 0;
			padding: 0 0 0 30px;
			font-size: 16px;
			line-height: 22px;
			font-weight: 400;
			color: #000000;
		}
	}
}

@media screen and (max-width: 1280px){
	.documents-container{
		.document-type-list{
			width: 100%;
		}
		.document-content{
			width: 100%;
			.privacy-policy{
				padding: 60px;
			}
		}
	}
}

@media screen and (max-width: 768px){
	.documents-container{
		.document-type-list{
			a{
				padding-left: 25px;
				padding-right: 25px;
			}
		}
		.document-content{
			width: 100%;
			.privacy-policy{
				padding: 60px 0px;
			}
		}
	}
}

@media screen and (max-width: 710px) {
	.documents-container{
		.document-type-list{
			a{
				padding-left: 20px;
				padding-right: 20px;
				font-size: 14px;
			}
		}
	}
	
}

@media screen and (max-width: 425px){
	.documents-container{
		padding: 60px 30px;
		.document-content{
			width: 100%;
			.scroll-to-top-btn{
				button{
					width: 30px;
					height: 30px;
				}
			}
			.privacy-policy{
				padding: 60px 0px;
			}
		}
	}
}